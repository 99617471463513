import EarnLinks from "components/earn-links";
import s from "components/earn-links/index.module.css";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import useMedia from "react-use/lib/useMedia";

export const RaiseCashTerms = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const isMobile = useMedia("(max-width: 767px)");

  return (
    <div className={s.container}>
      <EarnLinks />
      <div className={s.content}>
        <div className={s.contentHeader}>
          Raise {isMobile ? <br /> : " - "}
          Raise Cash Terms
        </div>
        <div className={s.subtext}>Last updated: April 1, 2025</div>
        <div className={s.mainContent}>
          <p className={s.pointParagraph}>
            Raise Cash is a promotional reward program offered by Raise
            Marketplace, LLC for Raise Members. For purposes of these Raise Cash
            Terms (the &quot;Raise Cash Terms&quot;), the terms
            &quot;Raise,&quot; &quot;we,&quot; &quot;us,&quot; and
            &quot;our&quot; refer to Raise The terms &quot;Member,&quot;
            &quot;you,&quot; and &quot;your&quot; apply to you as a Member of
            the Raise Cash program (&quot;Raise Cash Program&quot;). The term
            &quot;account&quot; refers to your Raise Member account and the term
            &quot;Raise Cash&quot; refers to the promotional rewards earned as
            reflected in your Member account from time to time pursuant to these
            Raise Cash Terms. Raise may offer a variety of Raise Cash rewards
            pursuant to various programs (&quot;Raise Cash Programs&quot; or
            &quot;Programs&quot;) in connection with the Raise mobile
            application (collectively, the &quot;App&quot;). These Raise Cash
            Terms contain important information regarding the Raise Cash
            Program. Please read these Raise Cash Terms carefully. These Raise
            Cash Terms are a binding agreement between you and Raise and will
            govern your participation in any and all Raise Cash Programs. By
            participating in any of the Raise Cash Programs, including earning
            and redeeming any Raise Cash offered under any of the Programs, you
            agree to these Raise Cash Terms, as well as the{" "}
            <Link className={s.link} to="/earn/terms">
              Terms of Use
            </Link>{" "}
            (&quot;Terms of Use&quot;) that govern your use of Raise generally,
            which are incorporated herein and can be found on the App. Raise
            reserves the right to modify these Raise Cash Terms at any time,
            without notice to you, so it is important that you check the Raise
            Cash Terms periodically. The Raise Cash Terms can be found
            throughout the App.
          </p>

          <div className={s.mainContentTopic}>1. Overview</div>
          <p className={s.pointParagraph}>
            Raise may offer one or more Raise Cash Programs under which you may
            have the opportunity to earn Raise Cash, which is redeemable on the
            App. Raise Cash Programs may include additional terms and conditions
            that apply to a Raise Cash Program (&quot;Additional Terms&quot;),
            as well your participation in activities allowing you to earn Raise
            Cash (collectively, &quot;Activities&quot;). There may be
            limitations on the Activities and Raise Cash, so you should always
            be sure to review all applicable Additional Terms before deciding to
            participate in any particular Raise Cash Program. Some of the
            limitations on Activities and Raise Cash include (without
            limitation), our right to change or limit your ability to
            participate in certain Activities or Raise Cash Programs; our right
            to change or limit the frequency of Activities and Raise Cash; our
            right to change or limit the amount of Raise Cash you can earn for
            any given Activities or during any given time period; our right to
            change the Activities and Cash Back available and our right to
            change the period during which any Raise Cash can be redeemed on the
            App. There are no costs or fees associated with the Programs or
            Raise Cash. The Company may limit, suspend, or terminate your
            ability to participate in a Raise Cash Program in its sole and
            absolute discretion, and may void any Raise Cash or potential Raise
            Cash you may have earned or accumulated in any Raise Cash Program,
            if we determine in our sole discretion that you have not complied
            with these Raise Cash Terms, the Terms of Use or any Additional
            Terms applicable to such participation or any particular Raise Cash
            Program. You agree to abide by the final and binding decisions of
            Raise regarding any Raise Cash Program and your participation in it.
            We reserve the right to change, suspend, or cancel all or a portion
            of a Raise Cash Program, including any Raise Cash you may have
            accrued, at any time without prior notice to you. Upon termination
            or discontinuance of any Program(s), unused or unredeemed Raise Cash
            associated with such Program(s) may immediately and automatically
            expire and may not be redeemable on the App or otherwise.
          </p>

          <div className={s.mainContentTopic}>2. Program Period</div>
          <p className={s.pointParagraph}>
            The Raise Cash Programs will continue until terminated, suspended,
            modified, or converted to another Raise Cash Program by Raise (the
            &quot;Program Period&quot;). Raise may change the Program Period at
            any time and may determine to terminate any Raise Cash Program
            Period in its sole discretion, in particular if it believes any
            Raise Cash Program is being abused or is otherwise not accomplishing
            the intended purpose of the Raise Cash Program.
          </p>

          <div className={s.mainContentTopic}>3. Eligibility</div>
          <p className={s.pointParagraph}>
            To participate in any Raise Cash Program, you must first register
            for a Raise member account on the App and become a Member of Raise.
            To register as a Member, you must meet the eligibility and
            registration criteria and comply with the authorized uses and
            prohibited activities specified in the Terms of Use. All Members
            aanyre required to comply with the Terms of Use at all times. Raise
            reserves the right to disqualify any Member from participating in
            the Raise Cash Program, and to invalidate any and all Raise Cash for
            abuse, fraud, or any violation of these Raise Cash Terms, any other
            Additional Terms of a Raise Cash Program, or the Terms of Use, at
            its sole discretion. You agree that you may only have ONE Member
            account on Raise and that to the extent Raise determines you have
            established more than ONE Member account on Raise, Raise will be
            permitted to cancel the Member account and any Cash Back associated
            with the additional Raise Member account(s) will be forfeited. Your
            Member account is personal to you and is non-transferrable. In the
            event we terminate your Member account for any reason, any Cash Back
            associated with your Member account is void. If you have registered
            for a Member account, you are responsible for safeguarding your
            password and any other credentials used to access the Member
            account. You, and not Raise, are responsible for any activity
            occurring in your Member account, whether or not you authorized that
            activity. If you become aware of any unauthorized access to your
            Member account, you should notify Raise immediately.
          </p>

          <div className={s.mainContentTopic}>
            4. Raise Referral Program Terms & Limitations
          </div>
          <p className={s.pointParagraph}>
            One of the Programs offered by Raise is the &quot;Raise Referral
            Program,&quot; whereby a Member is rewarded for referring friends to
            Raise. Once you have become a Member, you can begin earning Raise
            Cash under this Program (the &quot;Referral Raise Balance&quot;). If
            you refer a friend who then signs up for Raise, your friend must
            complete one transaction (gift card purchase,) for which Raise will
            give you $5 in Referral Raise Cash after each transaction (up to $25
            or more after the first transaction). Referrers can give their
            Friends $20 in Raise Cash to use towards their first transaction,
            Friends referred to Raise through this program have 180 days from
            the date they created their account to complete all (1) transaction
            in order to earn their $5 in Raise Cash and qualify the referring
            Member for their Referral Raise Cash. Raise users can invite their
            friends by using their personal/unique referral link. If a friend
            joins the app without clicking the unique referral link provided,
            neither party will receive the credit. Referrers can earn up to $100
            in Raise Cash through the referral program per calendar year, but
            there is no limit to the number of friends they can refer. Raise
            reserves the right to change any terms of the Raise Referral
            Program, including the minimum amount that must be spent to use the
            Referral Raise Cash, limit how or when the Referral Raise Cash can
            be redeemed, and whether there are limitations as to how any
            Referral Raise Cash can be used in combination with any Promotional
            Raise Cash or Promotional Raise Cash Programs. You can check your
            Raise Cash in your &quot;My Account&quot; section on the App. Raise
            may not honor any promotional rewards they believe to be in
            violation of this term or limitation, including if Raise determines
            you have more than one Member account.
          </p>

          <div className={s.mainContentTopic}>
            5. Promotional Raise Cash Programs Terms & Limitations
          </div>
          <p className={s.pointParagraph}>
            Raise also offers a variety of promotional Raise Cash Programs,
            whereby Raise will provide or offer certain persons Raise Cash in
            conjunction with various promotional events, programs, and
            initiatives. These promotional Raise Cash Programs will often
            identify a time limitation for redeeming any Raise Cash associated
            with a particular promotional Raise Cash Program, after which, any
            such Raise Cash will no longer be redeemable or valid for redemption
            on Raise. Raise may identify Additional Terms for any Promotional
            Raise Cash Program that may (i) limit who can use or redeem
            promotional Raise Cash, including if it is for new Members only,
            (ii) limit when promotional Raise Cash can be used, (iii) limit what
            promotional Raise Cash can be used to purchase, (iv) limit how much
            promotional Raise Cash is available to be redeemed in a particular
            Raise Cash Program, (v) limit the combination of any particular
            promotional Raise Cash with other promotional Raise Cash and (vi)
            provide other particular terms and conditions specifically related
            to that promotional Raise Cash Program. If any of these terms are
            not being complied with by any Member of Raise, then Raise reserves
            the right to not honor the redemption of any such Member&apos;s
            promotional Raise Cash. Furthermore, Raise reserves the right to
            limit the amount of promotional Raise Cash offered under any Raise
            Cash Program at any time, including the right to terminate any Raise
            Cash Program at its sole discretion. Raise, in its sole discretion,
            may at any time change minimum spend thresholds for taking advantage
            of any particular promotional Raise Cash Program. This change will
            be reflected at the time of check-out for the Member. Again, to the
            extent any promotional Raise Cash Program is limited to new Members,
            Raise may not honor any promotional Raise Cash they believe to be in
            violation of this term or limitation, including if Raise determines
            you have more than one Member account on Raise.
          </p>

          <div className={s.mainContentTopic}>
            6. Promotional Raise Cash Earned through Raise
          </div>
          <p className={s.pointParagraph}>
            Each Member is permitted to earn Raise Cash each time a Member adds
            funds to their Raise Cash or purchases a gift card from Raise. Each
            time you purchase a gift card, we will post Raise Cash to your
            Member account for redemption ONLY on Raise. Raise Cash earned
            through Raise will have an expiration timeframe of (180) days
            from the date it was earned. Raise reserves the right to change,
            suspend or terminate the Raise Cash Program, or any part of it, at
            any time and at our sole discretion. Notice will be provided via
            email to the email associated with your Member account.
          </p>

          <div className={s.mainContentTopic}>7. Redemption of Raise Cash </div>
          <p className={s.pointParagraph}>
            Raise Cash is redeemable toward purchases of gift cards and other
            products solely sold on Raise, and you can redeem it during the
            checkout process. All redemptions of your Raise Cash are final. If
            you use Raise Cash to purchase a gift card on Raise and Raise
            determines the gift card is subject to a refund, we will refund you
            any Raise Cash used on that particular purchase into your Member
            account. Likewise, we reserve the right to remove any Raise Cash
            earned where the qualifying Activities or event is no longer valid.
            To redeem your Raise Cash, do nothing. The checkout automatically
            defaults to applying Raise Cash during the checkout process. When
            you get to Checkout, you&apos;ll see the total amount of Raise Cash
            available to redeem with your purchase. Raise Cash will be applied
            toward your order and the dollar value will be deducted from your
            transaction total, unless you toggle off the switch to indicate that
            you do not want the Raise Cash applied. You will be asked to use
            another payment method to pay the remaining balance. Raise Cash is
            applied in full at the time of checkout. You cannot redeem Raise
            Cash for more than the total amount of your purchase. Members may
            apply all or none of their Raise Cash to a transaction, provided
            that Member pays the remaining balance with their chosen form of
            payment. Raise Cash will remain in your Member account until the
            relevant expiration date of such Raise Cash.
          </p>

          <div className={s.mainContentTopic}>
            8. Third-Party Payment Providers and Distribution of Raise Cash
          </div>
          <p className={s.pointParagraph}>
            Once you have accumulated at least Twenty-five Dollars (US $25.00)
            Raise Cash in your Raise account (the “Minimum Distribution
            Amount”), you may elect to have Raise distribute your Raise Cash via
            one or more of the available distribution options as shown in the
            App. The available distribution options are subject to change
            without notice to you, in accordance with these Terms, and you have
            no vested right to any particular form or method of distribution.
            You may not obtain any cash, money, or anything of value in exchange
            for your Raise Cash prior to reaching the Minimum Distribution
            Amount, or if subsequently, your Raise Cash falls below the Minimum
            Distribution Amount, for as long as your Raise Cash remains below
            the Minimum Distribution Amount. The Minimum Distribution Amount is
            subject to change by Raise at any time. Raise may, at its sole
            discretion, limit the amount of Raise Cash that you can distribute
            in any given period of time or for any particular transaction.
          </p>

          <div className={s.mainContentTopic}>9. Non-Transferability</div>
          <p className={s.pointParagraph}>
            Raise Cash is for promotional purposes only, and it is not
            transferable and cannot be bought, sold, gifted, or applied to any
            other account. Raise Cash is for the benefit only of the Member who
            has earned that Raise Cash in accordance with the relevant Program.
            Raise Cash from Raise can only be redeemed toward the purchase of
            gift cards, Cryptocurrency, or other products on Raise. Raise Cash
            is not a gift card. If your Member account is closed for violating
            our Terms of Use or these Raise Cash Terms, you may lose access to
            your Raise Cash and may no longer be able to redeem your Raise Cash
            on Raise.
          </p>

          <div className={s.mainContentSubtopic}>
            10. Service Credits & Returns
          </div>
          <p className={s.pointParagraph}>
            Raise Cash earned via purchases on Raise will be rescinded in the
            event that such purchase transaction is refunded or canceled for any
            reason. If you earn Raise Cash with a purchase transaction that is
            later refunded or canceled, and you have already redeemed or
            withdrawn the Raise Cash earned for that purchase transaction, Raise
            may do any combination of the following: (a) cancel the transaction,
            (b) interrupt delivery of any gift cards, (c) withhold subsequent
            Raise Cash awards, (d) refund the transaction amount less Raise Cash
            redeemed or withdrawn, or (e) deduct Raise Cash from your Member
            account, which may result in a negative balance in your Member
            account. Furthermore, Raise reserves the right to deduct future
            awards of Raise Cash from your Member account to satisfy any amount
            you may otherwise owe Raise under the{" "}
            <Link className={s.link} to="/earn/terms">
              Terms of Use
            </Link>{" "}
            or the Raise Cash Terms. Alternatively, Raise reserves the right to
            suspend your Member account until it is brought current.
          </p>

          <div className={s.mainContentTopic}>11. Raise Cash Expiration</div>
          <p className={s.pointParagraph}>
            Raise Cash earned from Raise that has not been redeemed or withdrawn
            within (180) days from the time such Raise Cash was earned by the
            Member under that Program may be deemed expired and removed from
            your Member account. Raise Cash earned under any other promotional
            Raise Cash Program will expire in accordance with the Additional
            Terms associated with such Raise Cash and Raise Cash Programs. The
            expiration date of any Raise Cash you have earned will be set forth
            in your Member account. Raise shall be the sole determiner of such
            expiration date and the expiration date determined by Raise shall be
            final and binding in all respects. If a Member does not redeem Raise
            Cash prior to the expiration date, the Member forfeits all such
            Raise Cash and the Raise Cash will cease to exist in the
            Member&apos;s account. Raise reserves the right to modify its Raise
            Cash expiration rules and policies for any Raise Cash Programs from
            time-to-time. If your Raise Cash expires pursuant to such
            then-current rules and policies, we may remove such Raise Cash from
            your Member account, without any compensation or further obligation
            to you regarding such expiring Raise Cash.
          </p>

          <div className={s.mainContentTopic}>12. Inactive Member Accounts</div>
          <p className={s.pointParagraph}>
            Any Member account that has not been logged into and Raise Cash
            either earned or redeemed or gift cards purchased, sold or used for
            two years or more may be deemed inactive and the Member account may
            be closed by Raise. In such instances, to request Member account
            reactivation (subject to our then current Raise Cash and our Terms
            of Use, and existing policies and procedures) you may reach us by
            contacting Raise Support at help@raiseearn.com. We may modify our
            inactive Member account rules and policies in our Raise Cash
            Programs from time to time, and if your Member account is deemed
            inactive pursuant to such then-current rules or policies, we may
            close your Member account, without any compensation or further
            obligation to you.
          </p>

          <div className={s.mainContentTopic}>13. Raise Cash Taxability</div>
          <p className={s.pointParagraph}>
            Raise Cash from Raise may be taxable, depending on the value of the
            item and the federal, state, and local tax laws applicable to the
            Member. Members are solely responsible for reporting such items on
            their tax returns and paying any associated tax liability.
          </p>

          <div className={s.mainContentTopic}>14. Disclaimer of Warranties</div>
          <p className={s.pointParagraph}>
            In addition to the Disclaimer of Warranties & Waiver of Claims
            section contained in the Terms of Use, Raise makes no warranty in
            any respect as to any Raise Cash, merchandise, or service available
            within the Raise Cash Programs. 15. Termination and Suspension of
            Participation in Program Raise may (in its sole discretion)
            prohibit, terminate or suspend any Member&apos;s participation in
            any aspect of a Program if Raise determines (or suspects) that such
            Member has engaged in or has attempted to engage in any: (i) act in
            violation of these Raise Cash Terms, the Terms of Use or any
            Additional Terms applicable to that particular Raise Cash Program;
            (ii) action that could damage, tamper with or corrupt the operation
            of any Raise Cash Program; (iii) act with the intent to annoy,
            harass or abuse any other person; (iv) inappropriate, uncooperative,
            disruptive, fraudulent, potentially fraudulent, or unusual behavior
            or activity; or (v) activity deemed, in the sole discretion of
            Raise, to be generally inconsistent with the intended operation or
            purpose of the particular Raise Cash Program. Any decision Raise
            makes relating to termination or suspension of any Member&apos;s
            participation in the Program shall be final and binding in all
            respects. Raise shall be the sole determiner in cases of suspected
            abuse, fraud, or breach of these Raise Cash Terms, the Terms of Use,
            or any Additional Terms. If you (or Raise) close your Member account
            or terminate your participation in a particular Program, all Raise
            Cash will be forfeited, even if your Member account is later
            reopened or you re-enroll in the Program.
          </p>

          <div className={s.mainContentTopic}>16. About Gift Cards</div>
          <p className={s.pointParagraph}>
            The gift cards offered by Raise are subject to each individual
            retailer&apos;s policies and program terms. For details, visit the
            relevant retailers&apos; websites for their particular terms &
            conditions governing a particular gift card.
          </p>

          <div className={s.mainContentTopic}>17. Release</div>
          <p className={s.pointParagraph}>
            By participating in any Raise Cash Program, a Member releases Raise,
            its parent company, subsidiaries, affiliates, suppliers, advertising
            partners and promotions agencies and their respective directors,
            officers, employees, and agents (collectively, &quot;Released
            Parties&quot;) from any and all liabilities, claims, losses,
            damages, costs or expenses, arising from or related to, the relevant
            Program, the Raise Cash Programs and/or the earning, use, loss or
            redemption of any Raise Cash.
          </p>

          <div className={s.mainContentTopic}>18. Indemnification</div>
          <p className={s.pointParagraph}>
            Member agrees to indemnify, defend, and hold the Released Parties
            harmless from and against any and all third-party claims, demands,
            liabilities, costs or expenses, including attorney&apos;s fees and
            costs, arising from, or related to (a) any breach by Member of any
            of these Raise Cash Terms (including any applicable Additional
            Terms), (b) any breach by Member of the Terms of Use, or (c) any
            violation by Member of applicable law.
          </p>

          <div className={s.mainContentTopic}>
            19. Entire Agreement, Governing Law, Venue & Arbitration
          </div>
          <p className={s.pointParagraph}>
            These Raise Cash Terms, the Terms of Use, and any Additional Terms
            reflect all of the terms applicable to your participation in any
            Raise Cash Program and the Raise Cash earned thereunder. The laws of
            the State of Illinois shall govern these Raise Cash Terms. Members
            hereby expressly consent to the exclusive jurisdiction and venue in
            the courts located in the State of Illinois, Cook County, for all
            matters arising in connection with these Raise Cash Terms or
            Member&apos;s participation in the Program. Member further agrees
            that any dispute related to Raise Cash, any Raise Cash Program, and
            the Raise Cash Terms will be subject to the arbitration provisions
            set forth in the Terms of Use, which all Members agree to before
            being permitted to participate in any Raise Cash on Raise. Your
            participation in the Raise Cash Program is governed by the Terms of
            Use and these Raise Cash Terms. In the event of a conflict between
            the terms and conditions of these Raise Cash Terms and the Terms of
            Use, these Raise Cash Terms shall control, except with respect to
            Section 18, in which case Section 19 (Legal Disputes) of the Terms
            of Use will govern and control in the event of a conflict.
          </p>

          <div className={s.mainContentTopic}>
            20. ​Changes to the Raise Cash Terms
          </div>
          <p className={s.pointParagraph}>
            The most current version of the Raise Cash Terms will be posted to
            the App, as indicated by the Last Updated date at the beginning of
            the Raise Cash Terms. Changes to the Raise Cash Terms will be
            effective upon posting to the App, unless otherwise required by
            applicable law. At our sole discretion, we may also inform you of
            changes to the Raise Cash Terms via email and/or in-app
            notifications. If you do not agree to the changes to the Raise Cash
            Terms, you should stop using your Member account and the App
            functionality related to Raise Cash. Your continued use of the App
            or Member account constitutes your acceptance of and agreement to be
            bound by the updated Raise Cash Terms.
          </p>
          <br />
          <br />
        </div>
      </div>
    </div>
  );
};
