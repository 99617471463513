export const INTERNAL_PATHS = {
  HOME: "/",
  ABOUT_US: "/about-us",
  PRESS: '/press',
  RAISE_APP: "https://slide.app.link/3eYV9rNsJRb",
  API_SOLUTIONS: "/business/enterprisesolutions",
  TERMS_AND_CONDITIONS: "/earn/terms",
  PRIVACY_POLICE: "/earn/privacy-policy",
  RAISE_CASH_TERMS: "/earn/raisecashterms",
  MISC: "/*",
};

export const EXTERNAL_LINKS = {
  GCX_MARKETPLACE: "https://gcx.raise.com",
  // TODO: replace with final link url
  DISCORD_COMMUNITY: "",
  TELEGRAM_COMMUNITY: "https://t.me/raisexyz",
};

export const MENU_LINKS = [
  { name: "Home", href: INTERNAL_PATHS.HOME },
  { name: "Products"},
  { name: "Raise App", href: INTERNAL_PATHS.RAISE_APP },
  { name: "API Solutions", href: INTERNAL_PATHS.API_SOLUTIONS },
  { name: "GCX", href: EXTERNAL_LINKS.GCX_MARKETPLACE, external: true },
  { name: "SmartCard", comingSoon: true },
  { name: "About"},
  { name: "About Us", href: INTERNAL_PATHS.ABOUT_US },
  { name: "Press", href: INTERNAL_PATHS.PRESS },
];
